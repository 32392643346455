<template>
  <div class="join">
    <Nav :isFlexible="false"/>
    <h1>This is an Joinus page</h1>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue'
export default {
  name: 'JoinUs',
  components:{
    Nav
  }
}
</script>